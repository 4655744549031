import { useEffect } from "react";

const LightParticles = (props) => {
    const { left_particles, right_particles, page } = props;

    const lp = []
    const rp = []

    if (left_particles){
        for(let i = 0; i < left_particles; i++){
            lp.push({})
        }
    }

    if (right_particles){
        for(let i = 0; i < right_particles; i++){
            rp.push({})
        }
    }

	useEffect(() => {

		function dragElement(elmnt) {
			var pos1 = 0,
				pos2 = 0,
				pos3 = 0,
				pos4 = 0;

			elmnt.onmousedown = dragMouseDown;

			function dragMouseDown(e) {
				e = e || window.event;
				e.preventDefault();
				// get the mouse cursor position at startup:
				pos3 = e.clientX;
				pos4 = e.clientY;
				document.onmouseup = closeDragElement;
				// call a function whenever the cursor moves:
				document.onmousemove = elementDrag;
				e.target.style.animationPlayState = "paused";
			}

			function elementDrag(e) {
				e = e || window.event;
				e.preventDefault();
				// calculate the new cursor position:
				pos1 = pos3 - e.clientX;
				pos2 = pos4 - e.clientY;
				pos3 = e.clientX;
				pos4 = e.clientY;
				// set the element's new position:
				elmnt.style.top = elmnt.offsetTop - pos2 + "px";
				elmnt.style.left = elmnt.offsetLeft - pos1 + "px";
			}

			function closeDragElement(e) {
				/* stop moving when mouse button is released:*/
				e.target.style.animationPlayState = "running";
				document.onmouseup = null;
				document.onmousemove = null;
			}
		}

		const particles = document.getElementsByClassName("particle-wrapper");

		for (let i = 0; i < particles.length; i++) {
			dragElement(particles[i]);
		}
	}, []);

	return (
		<div className="particle-containers">
			<div className="left-particles">
                {left_particles && (
                    lp.map((particle, index) => {
                        return (
                            <div className="particle-wrapper" id={`lp${index + 1}-${page}`} key={index}>
                                <div className="particle" id={`lparticle${index + 1}-${page}`}></div>
                            </div>        
                        )
                    })
                )}
            </div>
			<div className="right-particles">
                {right_particles && (
                    rp.map((particle, index) => {
                        return (
                            <div className="particle-wrapper" id={`rp${index + 1}-${page}`} key={index}>
                                <div className="particle" id={`rparticle${index + 1}-${page}`}></div>
                            </div>        
                        )
                    })
                )}
            </div>
        </div>
	);
};

export default LightParticles;
