import { lazy, Suspense } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { history, settings } from './browserHistory.js';
import { wrapHistory } from 'oaf-react-router';

//Regular components
import Home from './components/Home/Home.js';
import Footer from './components/Footer/Footer.js';

//Lazy load components
const E404 = lazy(() => import ('./components/404/404.js'));
const Services = lazy(() => import ('./components/Services/Services.js'));
const About = lazy(() => import ('./components/About/About.js'));
const Contact = lazy(() => import ('./components/Home/Contact.js'));
const Careers = lazy(() => import ('./components/Careers/Careers.js'));

const renderLoader = () => <div className="loading-container"></div>;

wrapHistory(history, settings);

function App() {

  return (
    <Router history={history}>
        <Suspense fallback={renderLoader()}>
          <Switch>
            <Route exact path="/" render={props => <Home {...props} />} />
            <Route exact path="/services/" render={props => <Services {...props} />} />
            <Route exact path="/about/" render={props => <About {...props} />} />
            <Route exact path="/contact/" render={props => <Contact {...props} />} />
            <Route exact path="/careers/" render={props => <Careers {...props} />} />
            <Route render={props => <E404 {...props} />} />
          </Switch>
        </Suspense>
        <Footer />
    </Router>
  );
}

export default App;