import { useRef, useEffect } from 'react';
import { Link } from 'react-router-dom'

import img_stair1 from '../../assets/home/stair1.png';
import img_stair2 from '../../assets/home/stair2.png';
import img_stair3 from '../../assets/home/stair3.png';
import img_stair4 from '../../assets/home/stair4.png';
import img_stair5 from '../../assets/home/stair5.png';
import img_stair6 from '../../assets/home/stair6.png';
import img_stair7 from '../../assets/home/stair7.png';
import img_stair8 from '../../assets/home/stair8.png';
import img_stair9 from '../../assets/home/stair9.png';
import img_stair10 from '../../assets/home/stair10.png';
import img_stair11 from '../../assets/home/stair11.png';
import img_stepping_up from '../../assets/home/stepping_up.svg';

function Career() {
  const container = useRef(null);
  const stair1 = useRef(null);
  const stair2 = useRef(null);
  const stair3 = useRef(null);
  const stair4 = useRef(null);
  const stair5 = useRef(null);
  const stair6 = useRef(null);
  const stair7 = useRef(null);
  const stair8 = useRef(null);
  const stair9 = useRef(null);
  const stair10 = useRef(null);
  const stair11 = useRef(null);
  const stepping_up = useRef(null);

  useEffect(() => {

    var vh = window.innerHeight;
    var vw = window.innerWidth;
    // console.log('viewport:', vw, 'x', vh)
    var content = container.current.getBoundingClientRect()
    // console.log('element', content.top)
    
    const handleScroll = () => {
      var scroll = window.scrollY - content.top;
      if (window.scrollY > content.top && window.scrollY < content.bottom) {
        stair1.current.style.left = 0;
        stair1.current.style.top = 0;

        stair2.current.style.right = 0;
        stair2.current.style.bottom = 0;

        stair3.current.style.left = 0;
        stair3.current.style.top = 0;

        stair4.current.style.right = 0;
        stair4.current.style.bottom = 0;

        stair5.current.style.left = 0;
        stair5.current.style.top = 0;

        stair6.current.style.right = 0;
        stair6.current.style.bottom = 0;

        stair7.current.style.left = 0;
        stair7.current.style.top = 0;

        stair8.current.style.right = 0;
        stair8.current.style.bottom = 0;

        stair9.current.style.left = 0;
        stair9.current.style.top = 0;

        stair10.current.style.right = 0;
        stair10.current.style.bottom = 0;

        stair11.current.style.left = 0;
        stair11.current.style.top = 0;

        stepping_up.current.style.bottom = (vh * 0.05) + scroll * 0.6 + "px";
        stepping_up.current.style.left = (vw * 0.5) + scroll * 0.5 + 'px';
      } else if (
				window.scrollY >= content.top - vh &&
				window.scrollY < content.bottom - vh
			) {
        stair1.current.style.left = scroll * 0.9 + "px";
        stair1.current.style.top = scroll * 0.2 + "px";

        stair2.current.style.right = scroll * 0.8 + "px";
        stair2.current.style.bottom = scroll * 0.01 + "px";

        stair3.current.style.left = scroll * 0.6 + "px";
        stair3.current.style.top = scroll * 0.1 + "px";

        stair4.current.style.right = scroll * 0.55 + "px";
        stair4.current.style.bottom = scroll * 0.009 + "px";

        stair5.current.style.left = scroll * 0.45 + "px";
        stair5.current.style.top = scroll * 0.04 + "px";

        stair6.current.style.right = scroll * 0.4 + "px";
        stair6.current.style.bottom = scroll * 0.02 + "px";

        stair7.current.style.left = scroll * 0.3 + "px";
        stair7.current.style.top = scroll * 0.01 + "px";

        stair8.current.style.right = scroll * 0.2 + "px";
        stair8.current.style.bottom = scroll * 0.01 + "px";

        stair9.current.style.left = scroll * 0.15 + "px";
        stair9.current.style.top = scroll * 0.009 + "px";

        stair10.current.style.right = scroll * 0.1 + "px";
        stair10.current.style.bottom = scroll * 0.004 + "px";

        stair11.current.style.left = scroll * 0.1 + "px";
        stair11.current.style.top = scroll * 0.004 + "px";
			}
    }

    window.addEventListener('scroll', handleScroll)

    return () => window.removeEventListener('scroll', handleScroll)

  }, [])

  return (
    <article className="career" ref={container}>
      <img src={img_stair1} id="stair1" ref={stair1} alt="stair1" />
      <img src={img_stair2} id="stair2" ref={stair2} alt="stair2" />
      <img src={img_stair3} id="stair3" ref={stair3} alt="stair3" />
      <img src={img_stair4} id="stair4" ref={stair4} alt="stair4" />
      <img src={img_stair5} id="stair5" ref={stair5} alt="stair5" />
      <img src={img_stair6} id="stair6" ref={stair6} alt="stair6" />
      <img src={img_stair7} id="stair7" ref={stair7} alt="stair7" />
      <img src={img_stair8} id="stair8" ref={stair8} alt="stair8" />
      <img src={img_stair9} id="stair9" ref={stair9} alt="stair9" />
      <img src={img_stair10} id="stair10" ref={stair10} alt="stair10" />
      <img src={img_stair11} id="stair11" ref={stair11} alt="stair11" />
      <img src={img_stepping_up} id="stepping_up" ref={stepping_up} alt="Step Up 2: The Sequel" />
      <h3>Join Our Team</h3>
      <div className="box" id="career-box">
        <div className="left">
          <h5 className="hide-desktop">Careers</h5>
          <div className="title center">Team Values</div>
          <p>Do you have a passion for creating beautiful fully featured applications?</p>
          <p>Are you a natural problem solver?</p>
          <p>Is motivating and inspiring the world your goal?</p>
          <p>Does analyzing and organizing data excite you?</p>
          <p>Technical or creative, we are looking for team members who are driven to perform their best.</p>
          <p>People who care about the work they do, and care about those around them are the people we want to work with.</p>
          <Link className="center" to="/about"><button className="button">Team Up With Us</button></Link>
        </div>
        <div className="right">
        </div>
      </div>
    </article>
  );
}

export default Career;