import { useRef } from "react";
import { Link } from "react-router-dom";
import LightParticles from './LightParticles.js';

import img_lightbulb from "../../assets/home/lightbulb.png";

function Ideas() {
	const container = useRef(null);

	return (
		<article className="ideas" ref={container}>
			<h3>Ideas Into Reality</h3>
			<img src={img_lightbulb} id="lightbulb" alt="Bright Idea" />
      		<LightParticles left_particles="" right_particles="7" page="ideas" />
			<div className="box" id="careers-box">
				<div className="left">
					<h5 className="hide-desktop">Ideas</h5>
					<div className="title center">Build Your Vision</div>
					<p>Do you have a great idea you'd like to turn into reality?</p>
					<p>
						Here at Quantum Sky, we specialize in turning dreams into reality.
						Share your idea with us, and let's get started on building the
						future together.
					</p>
					<p>
						We have the technology. We have the know how. We have the
						experience. But, most of all, we have the drive to get you from
						start to finish.
					</p>
					<p>The time is now. Don't let your opportunity pass you by.</p>
					<Link className="center" to="/contact">
						<button className="button">Contact Us</button>
					</Link>
				</div>
				<div className="right"></div>
			</div>
		</article>
	);
}

export default Ideas;
