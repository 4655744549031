import React from 'react';
import { hydrate, render } from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import './styles/index.scss';
import App from './App';
// import reportWebVitals from './reportWebVitals';

const AppHydrate = (
  <React.StrictMode>
    <HelmetProvider>
      <App />
    </HelmetProvider>
  </React.StrictMode>
)

const rootElement = document.getElementById("root");

if (rootElement.hasChildNodes()) {
  hydrate(AppHydrate, rootElement);
} else {
  render(AppHydrate, rootElement);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
