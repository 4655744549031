import { Helmet } from 'react-helmet-async';

import Hero from './Hero.js';
import Engineering from './Engineering.js';
import Ideas from './Ideas.js';
import Connecting from './Connecting.js';
import Career from './Career.js';
import Contact from './Contact.js';


function Home() {

  return (
    <div className="main">
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="description" content="Quantum Sky" />
        <title>Quantum Sky | Home</title>
        <link rel="canonical" href="https://quantumsky.io" />
        {/* <link rel="preload" href="/fonts/GoodTimesRegular.woff" as="font" type="font/woff" crossorigin="anonymous" />
        <link rel="preload" href="/fonts/Roboto-Light.woff" as="font" type="font/woff" crossorigin="anonymous" />
        <link rel="preload" href="/fonts/Roboto-Thin.woff" as="font" type="font/woff" crossorigin="anonymous"/>
        <link rel="preload" href="/assets/home/back.png" as="image" />
        <link rel="preload" href="/assets/home/mid-back.png" as="image" />
        <link rel="preload" href="/assets/home/mid-front.png" as="image" />
        <link rel="preload" href="/assets/home/front.png" as="image" />
        <link rel="preload" href="/assets/home/triangles-vignette.jpg" as="image" />
        <link rel="preload" href="/assets/home/moon.svg" as="image" />
        <link rel="preload" href="/assets/icons/writing-icon.svg" as="image" /> */}
      </Helmet>
      <section className="content-container landing-content">
        <Hero />
      </section>
      <section className="content-container background-color">
        <Engineering />
      </section>
      <section className="content-container margin-top-mobile background-color">
        <Ideas />
      </section>
      <section className="content-container margin-top-mobile background-color">
        <Connecting />
      </section>
      <section className="content-container margin-top-mobile background-color">
        <Career />
      </section>
      <section className="content-container margin-top-mobile background-color">
        <Contact />
      </section>
    </div>
  );
}

export default Home;