import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

const settings = {
  // announcementsDivId: "announcements",
  primaryFocusTarget: "h1, main h1, [role=main] h1",
  // This assumes you're setting the document title via some other means (e.g. React Helmet).
  // If you're not, you should return a unique and descriptive page title for each page
  // from this function and set `setPageTitle` to true.
  // BYO localization
  // documentTitle: (location: Location) => document.title,
  navigationMessage: (title: string, location: Location, action: Action): string => `Navigated to ${title}.`,
  // Return false if you're handling focus yourself for a specific history action.
  shouldHandleAction: (previousLocation: Location, nextLocation: Location, action: Action) => true,
  disableAutoScrollRestoration: false,
  announcePageNavigation: false,
  setPageTitle: false,
  handleHashFragment: true,
  // Set this to false if you are using HashRouter or MemoryRouter.
  restorePageStateOnPop: true,
  // Set this to true for smooth scrolling.
  // For browser compatibility you might want iamdustan's smoothscroll polyfill https://github.com/iamdustan/smoothscroll
  smoothScroll: true,
};

export { history, settings }