import { useRef, useEffect } from "react";
import VanillaTilt from "vanilla-tilt";

// import img_engineeringfuture from '../../assets/home/engineeringfuture.png';
import img_starship from "../../assets/home/starship.png";

function Tilt(props) {
	const { options, ...rest } = props;
	const tilt = useRef(null);

	useEffect(() => {
		VanillaTilt.init(tilt.current, options);
	}, [options]);

	return (
		<div ref={tilt} {...rest}>
			<div className="card-content card-0">
				<h5>Develop</h5>
				<h6>Rapid Software Development</h6>
				<p>
					We build, test, and deploy applications at lightning speed to get your
					idea off the ground.
				</p>
			</div>

			<div className="card-content card-1 hidden">
				<h5>Design</h5>
				<h6>Web Design - UI/UX</h6>
				<p>
					Cutting edge designs that will take your business to the next level.
				</p>
			</div>

			<div className="card-content card-2 hidden">
				<h5>Frontend</h5>
				<h6>Single Page Applications</h6>
				<p>Developing smooth and seamless user experiences.</p>
			</div>

			<div className="card-content card-3 hidden">
				<h5>Backend</h5>
				<h6>Backend Server Administration</h6>
				<p>APIs and more.</p>
			</div>

			<div className="card-content card-4 hidden">
				<h5>Data</h5>
				<h6>Data Analytics and Visualization</h6>
				<p>
					Cutting edge designs that will take your business to the next level.
				</p>
			</div>

			<div className="card-content card-5 hidden">
				<h5>SEO</h5>
				<h6>Search Engine Optimization</h6>
				<p>Developing smooth and seamless user experiences.</p>
			</div>

			<div className="card-content card-6 hidden">
				<h5>Cloud</h5>
				<h6>Cloud Infrastructure Platforms</h6>
				<p>
					Cutting edge designs that will take your business to the next level.
				</p>
			</div>

			<div className="card-content card-7 hidden">
				<h5>Manage</h5>
				<h6>Project Management / Consultation</h6>
				<p>
					From start to finish, we plan every step of the way to get to the
					finish line.
				</p>
			</div>
		</div>
	);
}

function Engineering() {
	const container = useRef(null);
	const starship = useRef(null);

	const tilt_options = {
		scale: 1.2,
		speed: 1000,
		max: 30,
		glare: true,
	};

	function sleep(ms) {
		return new Promise((res) => setTimeout(res, ms));
	}

	var h5s = document.getElementsByClassName("specialize");
	var cards = document.getElementsByClassName("card-content");

	let startCycle = useRef(false);

	const cycle = async () => {
		//loop over array
		//check for selected
		//remove selected
		//add selected to next element
		if (startCycle.current) {
			let current = 0;
			for (let i = 0; i < h5s.length; i++) {
				if (h5s[i].className === "specialize selected") {
					current = i;
				}
			}
			h5s[current].className = "specialize";
			cards[current].classList.add("fadeOut");
			await sleep(250);
			cards[current].className = "card-content hidden";
			if (current === h5s.length - 1) {
				current = 0;
				h5s[current].className = "specialize selected";
				cards[current].className = "card-content";
			} else {
				h5s[current + 1].className = "specialize selected";
				cards[current + 1].className = "card-content";
			}
		}
	};

	setInterval(cycle, 3000);

	const handleSelect = async (e, selected) => {
		e.preventDefault();
		startCycle.current = false;
		//fadeOut current
		//add class selected to target
		let current = 0;
		for (let i = 0; i < h5s.length; i++) {
			if (h5s[i].className === "specialize selected") {
				current = i;
			}
		}
		h5s[current].className = "specialize";
		cards[current].classList.add("fadeOut");
		await sleep(250);
		for (let i = 0; i < h5s.length; i++) {
			h5s[i].className = "specialize";
			cards[i].className = "card-content hidden";
		}
		h5s[selected].className = "specialize selected";
		cards[selected].className = "card-content";
	};

	useEffect(() => {
		var vh = window.innerHeight;
		// var vw = window.innerWidth;
		// console.log('viewport:', vw, 'x', vh)
		var content = container.current.getBoundingClientRect();
		// console.log('element', content.top)

		const handleScroll = () => {
			var scroll = window.scrollY - content.top;
			if (window.scrollY > content.top && window.scrollY < content.bottom) {
				starship.current.style.right = -scroll * 0.7 + "px";
				starship.current.style.top = -scroll * 0.7 + "px";
			} else if (
				window.scrollY >= content.top - vh / 2 &&
				window.scrollY < content.bottom - vh / 4
			) {
				startCycle.current = true;
			} else {
				startCycle.current = false;
			}
		};

		window.addEventListener("scroll", handleScroll);

		return () => window.removeEventListener("scroll", handleScroll);
	}, []);

	return (
		<article className="engineering" ref={container}>
			<img src={img_starship} id="starship" ref={starship} alt="Starship" />
			<h3>Engineering The Future</h3>
			<div className="box" id="engineering-box">
				<div className="left">
					<h4>With over 15 years of experience, we specialize in:</h4>
					<h5
						className="specialize selected"
						onClick={(e) => handleSelect(e, 0)}
					>
						Rapid Software Prototyping
					</h5>
					<h5 className="specialize" onClick={(e) => handleSelect(e, 1)}>
						Web Design - UI/UX
					</h5>
					<h5 className="specialize" onClick={(e) => handleSelect(e, 2)}>
						Single Page Applications
					</h5>
					<h5 className="specialize" onClick={(e) => handleSelect(e, 3)}>
						Backend Server Administration
					</h5>
					<h5 className="specialize" onClick={(e) => handleSelect(e, 4)}>
						Data Analytics and Visualization
					</h5>
					<h5 className="specialize" onClick={(e) => handleSelect(e, 5)}>
						Search Engine Optimization
					</h5>
					<h5 className="specialize" onClick={(e) => handleSelect(e, 6)}>
						Cloud Infrastructure Platforms
					</h5>
					<h5 className="specialize" onClick={(e) => handleSelect(e, 7)}>
						Project Management / Consultation
					</h5>
				</div>
				<div className="right">
					<Tilt
						className="card"
						onMouseEnter={() => (startCycle.current = false)}
						onMouseLeave={() => (startCycle.current = true)}
						options={tilt_options}
					/>
				</div>
			</div>
		</article>
	);
}

export default Engineering;
