import { useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';

import Particles from './Particles.js'

import img_counting_stars from '../../assets/home/wishes.svg';

function Hero() {
  const container = useRef(null);
  const triangles_vignette = useRef(null)
  const star_layer = useRef(null);
  const front = useRef(null);
  const mid_front = useRef(null);
  const mid_back = useRef(null);
  const back = useRef(null);
  const moon = useRef(null);
  const counting_stars = useRef(null);
  const logo = useRef(null);
  const headline = useRef(null);
  // const scrollContainer = useRef(null)

  useEffect(() => {

    var vh = window.innerHeight;
    var vw = window.innerWidth;
    console.log('viewport:', vw, 'x', vh)
    var content = container.current.getBoundingClientRect()
    // console.log('element', content.top)
    
    const handleScroll = () => {
      var scroll = window.scrollY - content.top;
      // console.log(window.scrollY);
      
      // scrollContainer.current.innerText = window.scrollY
      if (window.scrollY + 25 > content.top && window.scrollY < content.bottom) {
        if (vw >= 450){
          triangles_vignette.current.style.top = scroll * 1 + 'px';
          star_layer.current.style.top = scroll * 0.9 + 'px';
          moon.current.style.right = 100 + -scroll * 0.8 + 'px';
          counting_stars.current.style.left = (vw * 0.12) + -scroll * 1 + 'px';
        } else {
          moon.current.style.right = 10 + -scroll * 0.8 + 'px';
          counting_stars.current.style.left = (vw * 0.01) + -scroll * 0.5 + 'px';
        }
        
        front.current.style.bottom = -scroll * 0.02 + 'px';
        mid_front.current.style.bottom = -scroll * 0.2 + 'px';
        mid_back.current.style.bottom = -scroll * 0.3 + 'px';
        back.current.style.bottom = -scroll * 0.4 + 'px';
        
        moon.current.style.top = 100 + scroll * 2 + 'px';
        moon.current.style.transform = `rotate(${scroll * 0.05}deg)`
        logo.current.style.bottom = (vw * 0.12) + scroll * 1 + 'px';
        logo.current.style.transform = `scale(${1 + (scroll * 0.002)})`;
        headline.current.style.opacity = 1 - scroll * 0.01;
      }
    }

    window.addEventListener('scroll', handleScroll)

    return () => window.removeEventListener('scroll', handleScroll)

  }, [])

  return (
    <article className="hero" ref={container}>
      {/* <p id="scroll" ref={scrollContainer}>-</p> */}
      <img src="/assets/home/triangles-vignette.jpg" ref={triangles_vignette}id="triangles_vignette" alt="Sky" />
      <div className="particles" ref={star_layer} id="star_layer">
        <Particles />
      </div>
      <img src="/assets/home/moon.svg" ref={moon} id="moon" alt="That's no moon..." />
      <img src="/assets/home/back.png" ref={back} id="back" alt="back mountains" />
      <h1 ref={logo} id="logo">Quantum Sky</h1>
      <img src="/assets/home/mid-back.png" ref={mid_back} id="mid_back" alt="mid_back mountains" />
      <img src="/assets/home/mid-front.png" ref={mid_front} id="mid_front" alt="mid_front mountains" />
      <img src="/assets/home/front.png" ref={front} id="front" alt="front mountains" />
      <img src={img_counting_stars} ref={counting_stars} id="counting_stars" alt="The moon and the stars above" />
      <div className="hero-headline" ref={headline}>
        <h2>ENGINEERING CLOUD SOLUTIONS FOR THE FUTURE</h2>
        <h3>The sky is limitless</h3>
        <Link to='/contact/'><button><img src="/assets/icons/writing-icon.svg" height='550' width='448' alt="Writing" />Contact</button></Link>
        
      </div>
    </article>
  );
}

export default Hero;