import { useRef, useEffect } from 'react';
import { Link } from 'react-router-dom'

import LightParticles from './LightParticles.js';

import img_heart from "../../assets/home/connecting-heart-small.png";

function Connecting() {
  const container = useRef(null);
  const heart = useRef(null);

  useEffect(() => {

    var vh = window.innerHeight;
    // var vw = window.innerWidth;
    // console.log('viewport:', vw, 'x', vh)
    var content = container.current.getBoundingClientRect()
    // console.log('element', content.top)
    
    const handleScroll = () => {
      var scroll = window.scrollY - content.top;
      if (window.scrollY > content.top && window.scrollY < content.bottom) {
      } else if (
				window.scrollY >= content.top - vh &&
				window.scrollY < content.bottom - vh
			) {
				heart.current.style.top = scroll * 0.8 + "px";
			}
    }

    window.addEventListener('scroll', handleScroll)

    return () => window.removeEventListener('scroll', handleScroll)

  }, [])

  return (
    <article className="connecting" ref={container}>
      <img src={img_heart} id="heart" ref={heart} alt="Heart" />
      <h3>Connecting The Community</h3>
      <LightParticles left_particles="15" right_particles="15" page="connecting" />
      <div className="box">
        <div className="left">
        </div>
        <div className="right">
          <h5 className="hide-desktop">Connect</h5>
				  <div className="title">Giving Back</div>
          <p>At Quantum Sky, we believe our future is directly related to our community’s next generation.</p>
          <p>Find out how we give back to our communities and the planet through outreach and volunteer programs on our about page:</p>
          <Link to="/about"><button className="button">Visit About Page</button></Link>
        </div>
      </div>
    </article>
  );
}

export default Connecting;