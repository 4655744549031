import { useState, useRef, useEffect } from "react";
// import { Link } from "react-router-dom";

import img_heart1 from "../../assets/home/contact-heart1.png";
import img_heart2 from "../../assets/home/contact-heart2.png";
import img_heart3 from "../../assets/home/contact-heart3.png";
import img_heart4 from "../../assets/home/contact-heart4.png";
import img_heart5 from "../../assets/home/contact-heart5.png";
import img_heart6 from "../../assets/home/contact-heart6.png";
import img_contact_envelope from "../../assets/home/contact-envelope.svg";

function Contact() {
	const container = useRef(null);
	const heart1 = useRef(null);
	const heart2 = useRef(null);
	const heart3 = useRef(null);
	const heart4 = useRef(null);
	const heart5 = useRef(null);
	const heart6 = useRef(null);

	const [modalMode, setModalMode] = useState(false);
	const [submitted, setSubmitted] = useState(false);

	const [errorMessage, setErrorMessage] = useState(false);

	// const [centerContainer, setCenterContainer] = useState(0);

	const handleContactFocus = (e) => {
		//get all content containers
		//set display: none
		if (window.innerWidth < 450) {
			console.log("mobile contact mode");
			setModalMode(true);
			let containers = document.getElementsByClassName("content-container");
			for (let i = 0; i < containers.length - 1; i++) {
				containers[i].style.display = "none";
			}
		}
	};

	const handleCloseModalMode = (e) => {
		if (
			e.target.className !== "contact-form-input" &&
			e.target.className !== "contact-form" &&
			e.target.className !== "button"
		) {
			setModalMode(false);
			let containers = document.getElementsByClassName("content-container");
			for (let i = 0; i < containers.length - 1; i++) {
				containers[i].style.display = "flex";
			}
			setTimeout(() => {
				container.current.scrollIntoView({
					behavior: "instant",
					block: "start",
				});
			}, 200);
		}
	};

	const handleContactBlur = (e) => {
		e.preventDefault();
	};

	const sendIt = (e) => {
		e.preventDefault();
	};

	useEffect(() => {
		var vh = window.innerHeight;
		// var vw = window.innerWidth;
		// console.log('viewport:', vw, 'x', vh)
		var content = container.current.getBoundingClientRect();
		// console.log('element', content.top)

		// setCenterContainer(content.top);

		const handleScroll = () => {
			// var scroll = window.scrollY - content.top;
			if (window.scrollY > content.top && window.scrollY < content.bottom) {
			} else if (
				window.scrollY >= content.top - vh &&
				window.scrollY < content.bottom - vh
			) {
			}
		};

		window.addEventListener("scroll", handleScroll);

		return () => window.removeEventListener("scroll", handleScroll);
	}, []);

	return (
		<article
			className="contact"
			id="contact"
			ref={container}
			onClick={(e) => handleCloseModalMode(e)}
		>
			<img src={img_heart1} id="heart1" ref={heart1} alt="Heart" />
			<img src={img_heart2} id="heart2" ref={heart2} alt="Heart" />
			<img src={img_heart3} id="heart3" ref={heart3} alt="Heart" />
			<img src={img_heart4} id="heart4" ref={heart4} alt="Heart" />
			<img src={img_heart5} id="heart5" ref={heart5} alt="Heart" />
			<img src={img_heart6} id="heart6" ref={heart6} alt="Heart" />
			{!modalMode && <h3>Connect With Us</h3>}
			{!modalMode && (
				<div className="contact-header">
					<h4 className="hide-mobile">Contact</h4>
					<h5>Do you have a dream we can help you turn into reality?</h5>
					<h5>Send us an email, we'd love to hear from you!</h5>
				</div>
			)}
			<div className="box" id="contact-form">
				<div className={modalMode ? "left modal" : "left"}>
					{modalMode && (
						<div
							className="close-button"
							onClick={(e) => handleCloseModalMode(e)}
						>
							X
						</div>
					)}
					<h5 className="hide-desktop">Connect</h5>
					<div className="title hide-desktop">Contact</div>
					<form className="contact-form" onSubmit={(e) => sendIt(e)}>
						<div className="contact-form-half">
							<label className="contact-form-label" htmlFor="user_name">
								Name
							</label>
							<input
								className="contact-form-input"
								type="text"
								id="name"
								name="name"
								defaultValue=""
								placeholder="Name..."
								aria-label="name"
								required
								onClick={(e) => handleContactFocus(e)}
								onBlur={(e) => handleContactBlur(e)}
							/>
							<label className="contact-form-label" htmlFor="user_email">
								Email
							</label>
							<input
								className="contact-form-input"
								type="email"
								id="email"
								name="email"
								defaultValue=""
								placeholder="Email..."
								aria-label="email address"
								required
								onClick={(e) => handleContactFocus(e)}
								onBlur={(e) => handleContactBlur(e)}
							/>
						</div>
						<label className="contact-form-label" htmlFor="message">
							Questions / Comments
						</label>
						<textarea
							className="contact-form-input"
							id="contact_question"
							name="message"
							rows="5"
							cols="50"
							placeholder="Questions / Comments..."
							aria-label="questions / comments"
							required
							onClick={(e) => handleContactFocus(e)}
							onBlur={(e) => handleContactBlur(e)}
						/>
						{/* <div className="g-recaptcha" data-sitekey="6LcEvD0aAAAAAEchACESXdBy8PgtTzj2hylr3Jzt" data-callback="onSubmit" data-size="invisible"></div> */}
						{submitted ? (
							<div>
								Thank you for your message, we will get back to you as soon as
								possible.
							</div>
						) : (
							<input className="button" type="submit" value="Send It" />
						)}
						{errorMessage && (
							<div className="error">
								Error sending message, please try again soon or contact us
								directly at info@itsagoodlifefitness.com
							</div>
						)}
					</form>
				</div>
				<div className="right">
					<img
						src={img_contact_envelope}
						id="contact_envelope"
						height="350px"
						width="420px"
						alt="Contact"
					/>
				</div>
			</div>
		</article>
	);
}

export default Contact;
